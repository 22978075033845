<template>
  <div class="quota-empty">
    <iq-title class-name="mobileHeader" class="quota-empty__title">
      <Icon name="IconAttentionCircle" width="25px" height="25" />
      <span>Внимание!</span>
    </iq-title>

    <iq-title class-name="text">{{ description }}</iq-title>

    <div v-if="!isExporter" class="quota-empty__block">
      <iq-button icon="DriverPlus" color="main-dark" @onClick="handleAddDay">
        Добавить квоту по культуре
      </iq-button>
    </div>
  </div>
</template>

<script>
import { DIALOG_ADD_QUOTA } from '@/constants/dialogs'
import Icon from '@/UI/icon/Icon'
import IqButton from '@/UI/buttons/IqButton'
import IqTitle from '@/UI/typography/IqTitle'
export default {
  name: 'QuotaEmpty',
  components: { IqButton, IqTitle, Icon },
  computed: {
    description() {
      return this.isExporter
        ? 'Вам не выделено ни одной квоты'
        : 'У вас не создано ни одной квоты'
    },
  },
  methods: {
    handleAddDay() {
      this.setDialog({ name: DIALOG_ADD_QUOTA, visible: true })
    },
  },
}
</script>

<style lang="sass">
.quota-empty
  background: $color-white
  border: $border-default
  box-sizing: border-box
  box-shadow: $box-shadow-default
  border-radius: 4px
  padding: 20px
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  height: clamp(400px, calc(100vh - 300px), calc(100vh - 300px))
  &__title.mobileHeader
    display: flex
    justify-content: center
    align-items: center
    margin-bottom: 0
  & > * + *
    margin-top: 20px
  .text
    color: #606266
    margin-top: 20px

@media (max-width: 1199px)
  .quota-empty
    background: transparent
    border: transparent
    box-shadow: none
    height: 30vh
    &__title.mobileHeader
      column-gap: 5px
      font-weight: 500
      font-size: 22px
      line-height: 22px
      span
        padding-top: 2px
    .text
      margin-top: 16px
      margin-bottom: 0
</style>
